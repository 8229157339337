import { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { StarBorder as StarBorderIcon } from "@mui/icons-material";
import { feedback } from "../../api/misc";
import { usePathname } from "next/navigation";

const NavbarFeedbackThoughts = ({
  accessToken,
  handlePostSubmit,
  handleCancel,
  setError,
}) => {
  const [rating, setRating] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const pathname = usePathname();
  const fullUrl = window.location.origin + pathname;
  const browserInfo = navigator.userAgent;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!feedbackMessage && !rating) {
      setError("Please provide a rating and/or message.");
      return;
    }

    setError("");

    // Send data
    try {
      // if (!accessToken) throw new Error("Unable to retrieve access token.");

      // Send to backend
      await feedback({
        accessToken,
        values: { message: feedbackMessage, rating: rating },
        browserInfo,
        route: fullUrl || pathname,
      });

      // Reset form fields
      setFeedbackMessage("");
      setRating("");

      // Close feedback & open thank-you
      handlePostSubmit();
    } catch (err) {
      console.error(err);
      setError("Sorry, an error occurred. Please try again later.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography component="legend">
          How would you rate your experience?
        </Typography>
        <Rating
          name="rating"
          value={rating}
          size="large"
          emptyIcon={
            <StarBorderIcon
              sx={{
                color: "accents.main",
              }}
              fontSize="inherit"
            />
          }
          sx={{ marginTop: "5px", marginBottom: "20px" }}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        <TextField
          id="message"
          label="Your thoughts"
          value={feedbackMessage}
          fullWidth
          variant="outlined"
          multiline
          minRows={4}
          inputProps={{ maxLength: 1000 }}
          onChange={(e) => setFeedbackMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: "8px 24px 16px 24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" color="error" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </DialogActions>
    </form>
  );
};

export default NavbarFeedbackThoughts;
