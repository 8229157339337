import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// Terms of Use text
export async function getTermsOfUse(
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/misc/terms_of_use`,
      "GET",
      null,
      { Authorization: adminToken }
    );

    logger("Response in getTermsOfUse function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getTermsOfUse function:", error);
    throw error;
  }
}

// Get Privacy Policy text
export async function getPrivacyPolicy(
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/misc/privacy_policy`,
      "GET",
      null,
      { Authorization: adminToken }
    );

    logger("Response in getPrivacyPolicy function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getPrivacyPolicy function:", error);
    throw error;
  }
}

export async function contact(
  values,
  browserInfo,
  route,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ ...values, adminToken });

    // Filter out properties with empty string values
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/misc/contact_us`,
      "POST",
      {
        ...filteredValues,
        browserInfo,
        route,
      },
      { Authorization: adminToken }
    );

    logger("Response in contact function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in contact function:", error);
    throw error;
  }
}

// Share thoughts / feedback
export async function feedback({ accessToken, values, browserInfo, route }) {
  try {
    // Submit depending on if user is logged in
    let endpoint;
    if (!accessToken) {
      endpoint = "v1/public/global/misc/nrs_share_thoughts";
    } else {
      endpoint = "v1/public/regional/auth/user/misc/nrs_share_thoughts";
    }

    if (!values?.message && !values?.rating) {
      throw new Error("Either message or rating is required.");
    }

    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/${endpoint}`,
      "POST",
      {
        ...filteredValues,
        system_info: { info: browserInfo },
        ui_route: route,
      },
      {
        Authorization: accessToken
          ? accessToken
          : process.env.NEXT_PUBLIC_PROJECT_ID,
      }
    );

    logger("Response in feedback function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in feedback function:", error);
    throw error;
  }
}

// Report bugs
export async function reportIssue({
  accessToken,
  values,
  browserInfo = "",
  route = "",
}) {
  try {
    // Submit depending on if user is logged in
    let endpoint;
    if (!accessToken) {
      endpoint = "v1/public/global/misc/nrs_share_bugs";
    } else {
      endpoint = "v1/public/regional/auth/user/misc/nrs_share_bugs";
    }

    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/${endpoint}`,
      "POST",
      {
        ...filteredValues,
        system_info: { info: browserInfo },
        ui_route: route,
      },
      {
        Authorization: accessToken
          ? accessToken
          : process.env.NEXT_PUBLIC_PROJECT_ID,
      }
    );

    logger("Response in reportIssue function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in reportIssue function:", error);
    throw error;
  }
}

// get info for experiment dropdown
// return example:
// "environment_id": environment_id,
// "environment_title": environment_title,
// "embodiment_id": embodiment_id,
// "embodiment_title": embodiment_title,
// "experiment_genome_id": experiment_genome_id,
// "experiment_genome_title": experiment_genome_title,
// "feagi_refresh_rate": feagi_refresh_rate,
// "seed_genome_title": seed_genome_title,
// "seed_genome_thumbs_up": seed_genome_thumbs_up,
// "seed_genome_thumbs_down": seed_genome_thumbs_down
export async function getDropdownInfo(accessToken, sessionId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, sessionId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/experiments/brain_visualizer`,
      "POST",
      { session_id: sessionId },
      { Authorization: accessToken }
    );

    logger("Response in getDropdownInfo function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getDropdownInfo function:", error);
    throw error;
  }
}
